import React from "react";

import Page from "lib/@cms/components/layout/Page";
import { FormApplicationTemplate } from "lib/@forms/components/primitive";
import SKIP_A_PAYMENT_REQUEST_APPLICATION_TEMPLATE from "data/forms-templates/skip-a-payment-request-application.json";

export default function SkipAPaymentRequestApplicationPage() {
  return (
    <Page
      cms={{
        pageData: {
          MetaData: { title: "Skip A Payment Request Application", noRobots: true },
        },
      }}
      pathname="skip-a-payment-request-application"
    >
      {() => <Form />}
    </Page>
  );
}

// --- Components ---

export function Form() {
  return (
    <FormApplicationTemplate
      data={SKIP_A_PAYMENT_REQUEST_APPLICATION_TEMPLATE}
      securedFormFriendlyName={
        process.env.GATSBY_SECURED_FORM_SKIP_A_PAYMENT_REQUEST_APPLICATION_NAME
      }
    />
  );
}
